import { inject } from 'mobx-react';
import { Component } from 'react';

import AccountModel from '../models/domain/AccountModel';
import Controller from '../controllers/Header';
import ViewModel from '../view-models/Header';
import Models from '../models';
import CoursesModel from '../models/domain/CoursesModel';

@inject(Models.type.AccountModel, Models.type.CoursesModel)
export default class Header extends Component {
  private viewModel: ViewModel;

  constructor(props: any) {
    super(props);

    const accountModel: AccountModel = props[Models.type.AccountModel];
    const coursesModel: CoursesModel = props[Models.type.CoursesModel];

    this.viewModel = new ViewModel(accountModel, coursesModel);
  }

  render() {
    return <Controller viewModel={this.viewModel} />;
  }
}
