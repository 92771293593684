import React, { Component } from 'react';
import { observer } from 'mobx-react';

import notifications from '../events/Notification.event';
import {
  NotificationContext,
  NotificationType
} from '../../components/NotificationContext';
import {
  ControllerProps,
  SocketMessage,
  SocketAction
} from '../@types/AccountManager';

@observer
export default class AccountManagerController extends Component<ControllerProps> {
  static contextType = NotificationContext;
  context!: React.ContextType<typeof NotificationContext>;

  async componentDidMount(): Promise<void> {
    notifications.subscribe(this.onNotificationReceived);
    await this.props.viewModel.checkIfUserIsAuth(() => {
      this.context?.add(NotificationType.Error, 't:system.internalError');
    });
  }

  componentWillUnmount(): void {
    notifications.unsubscribe(this.onNotificationReceived);
  }

  render() {
    return <></>;
  }

  private onNotificationReceived = (
    notification: SocketMessage<{ message: string; id: string }>
  ) => {
    switch (notification.action) {
      case SocketAction.Notification: {
        this.context?.add(
          NotificationType.Info,
          notification.payload.message.toString()
        );
        break;
      }
      case SocketAction.Error: {
        this.context?.add(
          NotificationType.Error,
          notification.payload.message.toString(),
          5000,
          true
        );
        break;
      }
    }
  };
}
