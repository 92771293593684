import styled from 'styled-components';

export const ErrorPage = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px);
  background: ${({ theme }) => theme.backgroundColor};
  overflow: auto;
  justify-items: center;
  align-items: center;
  display: grid;
  color: ${({ theme }) => theme.textColor};
  z-index: 999;

  & > img,
  svg {
    align-self: end;
    width: 200px;
    display: block;
    margin-top: -40px;
    margin-bottom: -40px;
    height: 200px;
  }
`;
export const ErrorLine = styled.div`
  align-self: start;
  text-align: center;
`;
export const ErrorName = styled.div`
  padding-top: 30px;
  font-size: 26px;
  font-family: ${({ theme }) => theme.largeFont};
  font-weight: 500;
`;
