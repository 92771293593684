import { PureComponent } from 'react';

export default class LegalLazy extends PureComponent {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section>
        <div
          style={{
            maxWidth: '1024px',
            padding: '0 22px',
            position: 'relative',
            margin: 'auto',
            display: 'block',
            textAlign: 'center'
          }}
        >
          <div
            style={{
              textAlign: 'left',
              padding: '10px 0 40px',
              userSelect: 'text',
              WebkitUserSelect: 'text'
            }}
          >
            <div
              style={{
                margin: '35px auto',
                height: '36px',
                width: '430px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
            <div
              style={{
                width: '500px',
                height: '38px',
                margin: '-30px auto 55px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />

            <div
              style={{
                width: '100%',
                height: '200px',
                margin: '0 0 25px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />

            <div
              style={{
                width: '100%',
                height: '105px',
                margin: '0 0 25px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />

            <div
              style={{
                width: '100%',
                height: '105px',
                margin: '0 0 25px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}
