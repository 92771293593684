import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@keyframes lazyLoad {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 300% 0;
  }
}

* {
  outline: none !important;
  box-sizing: border-box;
}

html {
  overscroll-behavior: none;
  overflow-x: hidden;
}

h1,h2 {
  font-weight: 600;
  font-family: 'SF Pro Display', sans-serif;
}

h3,h4,h5,h6 {
  font-weight: 500;
}

body {
  margin: 0;
  padding: 100px 0 20px 0;
  font-family: 'SF Pro Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.backgroundColor};
  
  @media screen and (max-width: 550px) {
    transition: 0.5s margin;
    &.landing-menu-open {
      margin-right: -${({ theme }) => theme.mobNavWidth};
      margin-left: ${({ theme }) => theme.mobNavWidth};
    }
  }

  @media (pointer: none), (pointer: coarse) {
    @media (display-mode: standalone) {
      padding-top: 20px;
    }
  }
}

a {
  text-decoration: none;
  color: ${(props) => props.theme.primaryColor};
  ${({ theme }) => theme.transitionLong}

  &:hover {
    color: ${(props) => props.theme.primaryHoverColor};
  }
}

pre {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  user-select: text;
  -webkit-user-select: text;
  cursor: text;

  .linenumber {
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
  }
}
`;

export const theme = {
  mobNavWidth: '200px',

  backgroundColor: '#fff',
  backgroundColorRGB: '255,255,255',

  tailColorHigh: '#d5d5d5',

  elevatedBackgroundColor: '#f2f2f2',
  elevatedTextColor: '#000',
  elevatedTextColorRGB: '0, 0, 0',

  primaryColor: '#1A1819',
  primaryHoverColor: '#5b595a',

  primaryTextColor: '#fff',

  textColor: '#000',
  secTextColor: '#8d8d92',
  terTextColor: '#47474a',
  quaTextColor: '#2A2A2C',

  shadow: `
-webkit-box-shadow: 2px 4px 12px rgba(0,0,0,.08);
-moz-box-shadow: 2px 4px 12px rgba(0,0,0,.08);
-o-box-shadow: 2px 4px 12px rgba(0,0,0,.08);
box-shadow: 2px 4px 12px rgba(0,0,0,.08);
`,

  shadowHover: `
-webkit-box-shadow: 2px 4px 12px rgba(0,0,0,.16);
-moz-box-shadow: 2px 4px 12px rgba(0,0,0,.16);
-o-box-shadow: 2px 4px 12px rgba(0,0,0,.16);
box-shadow: 2px 4px 12px rgba(0,0,0,.16);
`,

  noShadow: `
-webkit-box-shadow: 0 0 0 rgba(0,0,0,0);
-moz-box-shadow: 0 0 0 rgba(0,0,0,0);
-o-box-shadow: 0 0 0 rgba(0,0,0,0);
box-shadow: 0 0 0 rgba(0,0,0,0);
`,

  largeFont: "'SF Pro Display', sans-serif",
  logoFont: "'Pacifico', cursive",

  transitionLong: `
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.5, 1);
  -moz-transition: all 0.5s cubic-bezier(0, 0, 0.5, 1);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.5, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.5, 1);
  `,
  transitionShort: `
  -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  -moz-transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  -o-transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  `,

  errorRGB: '231, 76, 60',
  successRGB: '0, 184, 148',
  infoRGB: '116, 185, 255',
  grayRGB: '141, 141, 146',

  black: '#000',

  gold: '#f39c12'
};
