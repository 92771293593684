import { createContext } from 'react';

export enum NotificationType {
  Error = 'e',
  Success = 's',
  Info = 'i'
}

export interface INotification {
  type: NotificationType;
  message: string;
  id: string;
}

export interface NotificationManagerState {
  notifications: INotification[];
}

export interface NotificationContextValue {
  add: (
    type: NotificationType,
    message: string,
    expireMs?: number,
    deduplication?: boolean
  ) => void;
}

export const NotificationContext =
  createContext<NotificationContextValue | null>(null);
