import ViewModel from '../view-models/AccountManager';

export interface ControllerProps {
  viewModel: ViewModel;
}

export enum DataRequestStatus {
  NoStarted = 'not_started',
  InProgress = 'in_progress',
  Ready = 'ready'
}

export interface DataRequest {
  status: DataRequestStatus;
  created_at: string;
  expires_at: string | null;
}

export interface User {
  dataRequest: DataRequest | null;
  profilePublic: boolean;
  identities: string[];
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
}

export type UpdateUserDto = Omit<
  User,
  | 'consentDate'
  | 'createdAt'
  | 'consent'
  | 'id'
  | 'email'
  | 'dataRequest'
  | 'identities'
>;

export interface Session {
  location: string;
  device: string;
  agent: string;
  user: string;
  ip: string;
  id: string;
  os: string;
}

export enum SocketAction {
  Notification = 'notification',
  Error = 'error'
}

export interface SocketMessage<T = any> {
  action: SocketAction;
  payload: T;
}
