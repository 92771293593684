/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { WithTranslationProps, withTranslation } from 'react-i18next';
import { PureComponent } from 'react';

import { ErrorLine, ErrorPage, ErrorName } from '../../../components/ErrorPage';
import { ReactComponent as ConfirmSvg } from '../../../svg/Confirm.svg';

class Confirm extends PureComponent<WithTranslationProps> {
  state = {
    code: null,
    message: null
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);
    window.document.title = 'Confirmation - WeGrowTech';
    const params = new URLSearchParams(window.location.search);

    this.setState({
      code: params.get('code'),
      message: params.get('message')
    });
  }

  render() {
    const { t } = this.props.i18n!;
    let message = this.state.message as unknown as string;

    if (message?.includes('link accepted')) {
      message = t('auth.emailChangeConfirm');
    }

    return (
      <ErrorPage>
        <ConfirmSvg />
        <ErrorLine>
          <ErrorName
            style={{
              paddingTop: '80px',
              maxWidth: '500px'
            }}
          >
            {message}
          </ErrorName>
        </ErrorLine>
      </ErrorPage>
    );
  }
}

export default withTranslation()(Confirm);
