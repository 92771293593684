import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { PureComponent, createRef } from 'react';
import styled from 'styled-components';

import { FormButton, FormInput, FormTitle } from '../../../components/forms';
import withRouter, { WithRouterProps } from '../../../components/withRouter';
import {
  NotificationContext,
  NotificationType
} from '../../../components/NotificationContext';

class Verify extends PureComponent<WithRouterProps<WithTranslationProps>> {
  private turnstileRef = createRef<TurnstileInstance>();

  state = {
    cfToken: ''
  };

  static contextType = NotificationContext;
  context!: React.ContextType<typeof NotificationContext>;

  render() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const t = this.props.i18n!.t;
    const search = new URLSearchParams(this.props.location.search);
    const queryId = search.get('id');

    const extraProps: any = {};

    if (queryId) {
      extraProps.value = queryId;
      extraProps.disabled = true;
    }

    return (
      <Container>
        <Title>{t('verify.title')}</Title>
        <Turnstile
          ref={this.turnstileRef}
          style={{
            position: 'absolute',
            zIndex: -1,
            pointerEvents: 'none'
          }}
          options={{
            action: 'verify-certificate'
          }}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          siteKey={process.env.REACT_APP_SITE_KEY!}
          onSuccess={this.onTokenSuccess}
          onExpire={this.onTokenExpired}
          onError={this.onTokenError}
        />
        <Form
          method="POST"
          action={`${process.env.REACT_APP_API_ENDPOINT}/certificate/view`}
        >
          {queryId && (
            <input required={true} type="hidden" name="id" value={queryId} />
          )}
          <FormInput
            required={true}
            name="id"
            type="text"
            id="certificate-id"
            placeholder={t('verify.placeholder')}
            {...extraProps}
          />
          <input
            required={true}
            type="hidden"
            name="cf"
            value={this.state.cfToken}
          />
          <FormButton disabled={!this.state.cfToken} type="submit">
            {t('verify.submit')}
          </FormButton>
        </Form>
      </Container>
    );
  }

  private onTokenSuccess = (token: string) => {
    this.setState({ cfToken: token });
  };

  private onTokenError = () => {
    this.context?.add(NotificationType.Error, 't:form.tokenError');
  };

  private onTokenExpired = () => {
    this.setState({ cfToken: '' });
  };
}

export default withTranslation()(withRouter(Verify));

const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  min-height: calc(100vh - 260px);
  padding: 20px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const Title = styled(FormTitle)`
  width: fit-content;
  font-size: 32px;
`;

const Form = styled.form`
  max-width: 370px;
  width: 100%;
  input {
    width: 100%;
    display: block;

    &:disabled {
      opacity: 0.5;
    }
  }
  button {
    width: 100%;
  }
`;
