import { observer } from 'mobx-react';
import { Component } from 'react';

import { ControllerProps } from '../@types/KeyboardManager';
import View from '../views/KeyboardManager';

@observer
export default class Controller extends Component<ControllerProps> {
  private keyDowns: Record<string, boolean> = {};

  componentDidMount(): void {
    window.addEventListener('contextmenu', this.handleContextMenu);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('blur', this.handleBlur);
  }

  componentWillUnmount(): void {
    window.removeEventListener('contextmenu', this.handleContextMenu);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('blur', this.handleBlur);
  }

  render() {
    return <View />;
  }

  private handleBlur = () => {
    this.keyDowns = {};
  };

  private handleKeyUp = (e: KeyboardEvent) => {
    delete this.keyDowns[this.getCode(e.code, e.key)];
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    this.keyDowns[this.getCode(e.code, e.key)] = true;
    this.handleShortcuts(e);
  };

  private handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  private handleShortcuts = (e: KeyboardEvent) => {
    if (
      this.keyDowns['Alt'] &&
      this.keyDowns['KeyI'] &&
      this.keyDowns['Meta']
    ) {
      delete this.keyDowns['KeyI'];
      e.preventDefault();
    }

    if (this.keyDowns['KeyP'] && this.keyDowns['Meta']) {
      delete this.keyDowns['KeyP'];
      e.preventDefault();
    }
  };

  private getCode = (code: string, key: string) => {
    if (['Meta', 'Alt'].includes(key)) {
      return key;
    }

    return code;
  };
}
