import { makeAutoObservable, toJS } from 'mobx';

import { APIRoutes } from '../../../routes';

export default class WebinarModel {
  /**
   * Private variables to store data
   */
  private _streamId: undefined | string;
  private _history: string[] = [];
  private loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Async Setters
   */
  public async loadWebinar() {
    if (this.loading) {
      return;
    }

    try {
      this.changeLoadingState(true);

      const response = await fetch(APIRoutes.Webinar, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.cleanUp();
        return;
      }

      const { live, history } = await response.json();
      this.setData(live, history);
    } catch (e) {
      this.cleanUp();
    }
  }

  /**
   * Private Sync Setters
   */
  private changeLoadingState(value: boolean) {
    this.loading = value;
  }

  private setData(streamId: string, history: string[]) {
    this._streamId = streamId;
    this._history = history;
    this.loading = false;
  }

  private cleanUp() {
    this._streamId = undefined;
    this.loading = false;
    this._history = [];
  }

  /**
   * Getters
   */
  public get isLoading(): boolean {
    return this.loading;
  }

  public get streamId(): string | undefined {
    return this._streamId;
  }

  public get history(): string[] {
    return toJS(this._history);
  }
}
