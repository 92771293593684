import { WithTranslationProps, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PureComponent } from 'react';

import withRouter, { WithRouterProps } from '../../../components/withRouter';
import routes from '../../../routes';
import LangButton from './LangButton';

class Footer extends PureComponent<WithRouterProps<WithTranslationProps>> {
  render() {
    if (this.props.location.pathname === routes.Quiz) {
      return <></>;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { t } = this.props.i18n!;

    const pathParts = this.props.location.pathname.split('/').slice(1);
    const isOnCoursePage =
      pathParts[0] === 'dashboard' &&
      pathParts[1] === 'courses' &&
      pathParts.length === 3;

    return (
      <Container
        data-dashboard-pad={pathParts[0] === 'dashboard'}
        data-course-pwa={isOnCoursePage}
      >
        <Delimiter />
        <CopyRow>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            © {new Date().getFullYear()} {process.env.REACT_APP_COPY}.{' '}
            {t('footer.rights')}
          </div>
          <div>
            <Status>
              <iframe
                sandbox="allow-scripts allow-popups allow-same-origin"
                title="Status"
                src="https://status.wegrowtech.io/badge?theme=light"
                width="184"
                height="30"
              ></iframe>
            </Status>
            <Link to={routes.Privacy}>{t('footer.privacy')}</Link>
            <Link to={routes.Terms}>{t('footer.terms')}</Link>
          </div>
        </CopyRow>
        <LangRow>
          <span
            style={{
              marginTop: '-2px'
            }}
          >
            {t('i18.change')}
          </span>
          <LangButton />
        </LangRow>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &[data-dashboard-pad='true'] {
    padding-left: 80px;
    @media (pointer: none), (pointer: coarse) {
      padding-left: 0;
      padding-bottom: 108px;
    }
  }

  &[data-course-pwa='true'] {
    @media screen and (max-width: 1080px) {
      @media (pointer: none), (pointer: coarse) {
        @media (display-mode: standalone) {
          padding-bottom: 208px;
        }
      }
    }
  }
`;

const LangRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  gap: 10px;
  color: ${(props) => props.theme.secTextColor};
  max-width: 1260px;
  width: 100%;
  padding: 0 32px 20px;

  & > span:first-child {
    margin-left: auto;
  }

  @media screen and (max-width: 750px) {
    & > span:first-child {
      margin-left: 0;
    }
  }
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 184px;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;

  iframe {
    border: 0;
    overflow: hidden;
  }
`;

const Delimiter = styled.div`
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.elevatedBackgroundColor};

  @media (pointer: none), (pointer: coarse) {
    display: none;
  }
`;

const CopyRow = styled.div`
  color: ${({ theme }) => theme.secTextColor};
  max-width: 1260px;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-weight: 500;

  gap: 20px;
  & > div:last-child {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    & > div:last-child {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
    }

    @media (pointer: none), (pointer: coarse) {
      gap: 10px;
      & > div:last-child {
        gap: 10px;
      }
    }
  }

  a {
    min-width: 95px;
    color: ${({ theme }) => theme.secTextColor};
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export default withTranslation()(withRouter(Footer));
