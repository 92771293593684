/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PureComponent } from 'react';
import { WithTranslationProps, withTranslation } from 'react-i18next';
import { styled } from 'styled-components';

class LangButton extends PureComponent<WithTranslationProps> {
  render() {
    const t = this.props.i18n!.t;

    return (
      <ButtonContainer
        onClick={() => {
          const next = t('i18.next');
          localStorage.setItem('wgt-i18', next);
          this.props.i18n?.changeLanguage(next);
        }}
      >
        <span
          style={{
            marginTop: '-3px'
          }}
        >
          {t('i18.icon')}
        </span>
        <span>{t('i18.title')}</span>
      </ButtonContainer>
    );
  }
}

const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  font-family: ${({ theme }) => theme.largeFont};
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    transform: scale(1.05);
  }
`;

export default withTranslation()(LangButton);
