import { makeAutoObservable, toJS } from 'mobx';
import { v4 } from 'uuid';

import { Session, UpdateUserDto, User } from '../../@types/AccountManager';
import notificationEvents from '../../events/Notification.event';
import { SignInDto } from '../../@types/AccountModel';
import { Provider } from '../../@types/Auth';
import { APIRoutes } from '../../../routes';
import growthbook from '../../../growthbook';

export default class AccountModel {
  /**
   * Private variables to store data
   */
  private user: User | null = null;
  private loading = true;

  private systemOffline = false;

  private deleteLock = false;

  private currentSession: string | null = null;
  private sessions: Session[] = [];
  private sessionsLoading = false;

  private accountUpdateLoading = false;

  private requestDataLock = false;
  private loadingDeleteArchive = false;

  // OAuth
  private oauthState = '';

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Sync Setters
   */

  public generateOAuthState(): string | undefined {
    if (this.loading) {
      return;
    }

    this.oauthState = v4();
    return this.oauthState;
  }

  public setOAuthLock(lock: boolean) {
    this.loading = lock;
  }

  /**
   * Public Async Setters
   */

  public async logout(scope: 'global' | 'others' | 'local' = 'local') {
    if (scope !== 'others') {
      this.changeLoadingState(true);
      try {
        (window as any).google.accounts.id.disableAutoSelect();
      } catch (e) {
        console.error(e);
      }
    } else {
      this.changeSessionsLoadingState(true);
    }

    const query = new URLSearchParams({
      scope
    });

    try {
      const response = await fetch(`${APIRoutes.AuthMe}?${query.toString()}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (response.status === 200) {
        const { sessions, currentSession } = await response.json();
        this.setSessions(sessions, currentSession);
      }
    } finally {
      if (scope !== 'others') {
        this.cleanupStates();
      } else {
        this.changeSessionsLoadingState(false);
      }
    }
  }

  public async requestData(): Promise<string | null> {
    if (this.requestDataLock || !this.user) {
      return 't:errors.unknown';
    }

    try {
      this.setRequestDataLock(true);

      const response = await fetch(APIRoutes.AuthRequestData, {
        method: 'POST',
        credentials: 'include'
      });

      if (response.status !== 204) {
        this.setRequestDataLock(false);
        const error = await response.json();
        return error?.message || 't:errors.unknown';
      }

      this.cleanupStates();
      return null;
    } catch {
      this.setRequestDataLock(false);
      return 't:errors.unknown';
    }
  }

  public async deleteDataArchive(): Promise<string | null> {
    if (this.loadingDeleteArchive || !this.userData) {
      return 't:errors.unknown';
    }

    try {
      this.setLoadingDeleteArchive(true);

      const response = await fetch(APIRoutes.DownloadData, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (response.status !== 204) {
        this.setLoadingDeleteArchive(false);
        const error = await response.json();
        return error?.message || 't:errors.unknown';
      }

      const { dataRequest: _, ...user } = this.userData;
      this.setUser({
        ...user,
        dataRequest: null
      });

      this.setLoadingDeleteArchive(false);
      return null;
    } catch {
      this.setLoadingDeleteArchive(false);
      return 't:errors.unknown';
    }
  }

  public async deleteAccount(
    certificates?: 'keep' | 'delete' | ''
  ): Promise<string | null> {
    if (this.deleteLock || !this.user) {
      return 't:errors.unknown';
    }

    try {
      this.setDeleteLock(true);

      const response = await fetch(APIRoutes.AuthAccount, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          certificates: certificates ? certificates : undefined
        })
      });

      if (response.status !== 204) {
        this.setDeleteLock(false);
        const error = await response.json();
        return error?.message || 't:errors.unknown';
      }

      this.cleanupStates();
      return null;
    } catch {
      this.setDeleteLock(false);
      return 't:errors.unknown';
    }
  }

  public async loadSessions() {
    if (this.sessionsLoading || !this.user?.id) {
      return;
    }

    try {
      this.changeSessionsLoadingState(true);

      const response = await fetch(APIRoutes.AuthSessions, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.setSessions([], null);
        return;
      }

      const { sessions, currentSession } = await response.json();
      this.setSessions(sessions, currentSession);
    } catch (e) {
      this.setSessions([], null);
    }
  }

  public async checkIfUserIsAuth(onError?: () => void) {
    try {
      const authResponse = await fetch(APIRoutes.AuthMe, {
        method: 'GET',
        credentials: 'include'
      });

      this.changeLoadingState(false);

      if (authResponse.status === 200) {
        const authData = await authResponse.json();
        this.setUser(authData);
      }
    } catch {
      onError?.();
      this.setSystemOffline(true);
    }
  }

  public async signUpUser(
    payload: SignInDto,
    onError: (message: string) => void
  ) {
    try {
      this.changeLoadingState(true);

      const authResponse = await fetch(APIRoutes.AuthSignUp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      this.changeLoadingState(false);

      if (authResponse.status !== 204) {
        const error = await authResponse.json();
        const message: string = error.message;

        if (message?.toLocaleLowerCase()?.includes('signups not allowed')) {
          onError('t:form.signUpNotAllowed');
        } else {
          onError(message);
        }
        return;
      }
    } catch (e: any) {
      this.changeLoadingState(false);
      onError('t:system.internalError');
    }
  }

  public async signInUser(
    email: string,
    cfToken: string,
    onError: (message: string) => void
  ) {
    try {
      this.changeLoadingState(true);

      const authResponse = await fetch(APIRoutes.AuthSignIn, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: cfToken,
          email
        })
      });

      this.changeLoadingState(false);

      if (authResponse.status !== 204) {
        const error = await authResponse.json();
        onError(error.message);
        return;
      }
    } catch (e: any) {
      this.changeLoadingState(false);
      onError('t:system.internalError');
    }
  }

  public async unlinkAccount(
    provider: Provider,
    onError: (message: string) => void
  ) {
    try {
      const authResponse = await fetch(APIRoutes.AuthIdentity, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          provider
        })
      });

      if (authResponse.status !== 204) {
        const response = await authResponse.json();
        onError(response.message);
        return;
      }

      const user = this.userData;
      if (!user) {
        return;
      }

      this.setUser({
        ...user,
        identities: user.identities.filter((p) => p !== provider)
      });
    } catch (e: any) {
      onError('t:system.internalError');
    }
  }

  public async signInOIDC(
    provider: Provider,
    idToken: string,
    state: string,
    onError: (message: string) => void,
    firstName?: string,
    lastName?: string
  ) {
    try {
      if (state !== this.oauthState) {
        console.debug('State mismatches');
        return;
      }

      if (provider === 'google' && this.loading) {
        return;
      }

      this.setOAuthLock(true);

      const authResponse = await fetch(APIRoutes.OIDC(provider), {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: idToken,
          firstName,
          lastName
        })
      });

      this.setOAuthLock(false);
      const response = await authResponse.json();

      if (authResponse.status !== 201) {
        onError(response.message);
        return;
      }

      this.setUser(response);
    } catch (e: any) {
      this.setOAuthLock(false);
      onError('t:system.internalError');
    }
  }

  public async verifyOtp(
    email: string,
    token: string,
    onError: (message: string) => void,
    resendType?: 'sign-in' | 'sign-up'
  ) {
    try {
      this.changeLoadingState(true);

      const authResponse = await fetch(APIRoutes.AuthVerify, {
        method: resendType ? 'PUT' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          email,
          ...(resendType
            ? {
                type: resendType
              }
            : {})
        })
      });

      this.changeLoadingState(false);
      let response: any = {};

      if (authResponse.status !== 204) {
        response = await authResponse.json();
      }

      if (resendType && authResponse.status !== 204) {
        onError(response.message);
        return;
      } else if (!resendType && (authResponse.status !== 201 || !response.id)) {
        onError(response.message || 't:form.otpError');
        return;
      }

      if (!resendType) {
        this.setUser(response);
      }
    } catch (e) {
      this.changeLoadingState(false);
      onError(resendType ? 't:system.internalError' : 't:form.otpError');
    }
  }

  public async saveAccountData(
    data: UpdateUserDto,
    onError: (message: string) => void
  ) {
    if (this.accountUpdateLoading || !this.user?.id) {
      return;
    }

    try {
      this.setAccountUpdateLoading(true);

      const response = await fetch(APIRoutes.AuthAccountData, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(data)
      });

      const payload = await response.json();
      this.setAccountUpdateLoading(false);

      if (response.status !== 200) {
        onError(payload.message);
        return;
      }

      this.setUser(payload);
    } catch {
      this.setAccountUpdateLoading(false);
      onError('t:system.internalError');
    }
  }

  public async changeEmail(
    email: string,
    onError: (message: string) => void,
    onSuccess: () => void
  ) {
    if (this.accountUpdateLoading || !this.user?.id) {
      return;
    }

    try {
      this.setAccountUpdateLoading(true);

      const response = await fetch(APIRoutes.AuthAccountEmail, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({
          email
        })
      });

      this.setAccountUpdateLoading(false);

      if (response.status !== 204) {
        const payload = await response.json();
        onError(payload.message);
        return;
      }

      onSuccess();
    } catch {
      this.setAccountUpdateLoading(false);
      onError('t:system.internalError');
    }
  }

  /**
   * Private Sync Setters
   */

  private cleanupStates() {
    this.changeSessionsLoadingState(false);
    this.changeLoadingState(false);
    this.setRequestDataLock(false);
    this.setDeleteLock(false);
    this.setUser(null);
  }

  private setDeleteLock(value: boolean) {
    this.deleteLock = value;
  }

  private setRequestDataLock(value: boolean) {
    this.requestDataLock = value;
  }

  private setLoadingDeleteArchive(value: boolean) {
    this.loadingDeleteArchive = value;
  }

  private setSessions(sessions: Session[], currentSession: string | null) {
    this.currentSession = currentSession;
    this.sessionsLoading = false;
    this.sessions = sessions;
  }

  private changeSessionsLoadingState(value: boolean) {
    this.sessionsLoading = value;
  }

  private setAccountUpdateLoading(value: boolean) {
    this.accountUpdateLoading = value;
  }

  private setSystemOffline(value: boolean) {
    this.systemOffline = value;
  }

  private setUser(value: User | null) {
    growthbook.updateAttributes({
      id: value?.id || null,
      profilePublic: value?.profilePublic || false
    });

    notificationEvents.stateChanged(!!value);
    this.user = value;
  }

  private changeLoadingState(value: boolean) {
    this.loading = value;
  }

  /**
   * Getters
   */
  public get isLoading(): boolean {
    return this.loading;
  }

  public get isLoadingDeleteArchive(): boolean {
    return this.loadingDeleteArchive;
  }

  public get isLogged(): boolean {
    return !!this.user;
  }

  public get userData(): User | null {
    return toJS(this.user);
  }

  public get sessionsData(): Session[] {
    return toJS(this.sessions);
  }

  public get currentSessionId(): string | null {
    return this.currentSession;
  }

  public get isSystemOffline(): boolean {
    return this.systemOffline;
  }

  public get isDeleteLocked(): boolean {
    return this.deleteLock;
  }

  public get isRequestLocked(): boolean {
    return this.requestDataLock;
  }

  public get sessionsLoadingState(): boolean {
    return this.sessionsLoading;
  }

  public get isAccountUpdateLoading(): boolean {
    return this.accountUpdateLoading;
  }
}
