import { GrowthBook } from '@growthbook/growthbook-react';
import { useEffect } from 'react';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_SDK
});

export function GrowthbookInit() {
  useEffect(() => {
    growthbook.init({ streaming: true });
  }, []);

  return <></>;
}

export default growthbook;
