import styled from 'styled-components';
import { PureComponent } from 'react';

export default class BlankLoading extends PureComponent<{ sidebar?: boolean }> {
  render() {
    return <Container data-sidebar={this.props.sidebar} />;
  }
}

const Container = styled.div`
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.secTextColor};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-sidebar='true'] {
    left: 80px;

    @media (pointer: none), (pointer: coarse) {
      display: none;
    }
  }
`;
