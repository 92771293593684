import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import resourcesToBackend from 'i18next-resources-to-backend';
import { ThemeProvider } from 'styled-components';
import { initReactI18next } from 'react-i18next';
import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import { Provider } from 'mobx-react';
import i18n from 'i18next';

import NotificationManager from './components/NotificationManager';
import growthbook, { GrowthbookInit } from './growthbook';
import { GlobalStyle, theme } from './theme';
import Models from './app/models';
import routes from './routes';

/** Providers */
import { AccountManager, Header, KeyboardManager } from './app/providers';

/** Pure Views */
import BlankLoading from './components/lazy/BlankLoading';
import LegalLazy from './components/lazy/LegalLazy';
import NotFound from './app/views/Errors/NotFound';
import HomeLazy from './components/lazy/HomeLazy';
import Verify from './app/views/Cert/Verify';
import Confirm from './app/views/Confirm';
import Footer from './app/views/Footer';

/** LAZY Providers */
const DashboardHome = React.lazy(() => import('./app/providers/DashboardHome'));
const MyAccount = React.lazy(() => import('./app/providers/MyAccount'));
const Auth = React.lazy(() => import('./app/providers/Auth'));
const DashboardManager = React.lazy(
  () => import('./app/providers/DashboardManager')
);
const DashboardCourse = React.lazy(
  () => import('./app/providers/DashboardCourse')
);
const DashboardBrowse = React.lazy(
  () => import('./app/providers/DashboardBrowse')
);
const DashboardCerts = React.lazy(
  () => import('./app/providers/DashboardCertificates')
);
const DashboardCourseReviews = React.lazy(
  () => import('./app/providers/DashboardCourseReviews')
);
const DashboardCourseModule = React.lazy(
  () => import('./app/providers/DashboardCourseModule')
);
const DashboardWebinar = React.lazy(
  () => import('./app/providers/DashboardWebinar')
);
const Quiz = React.lazy(() => import('./app/providers/Quiz'));

/** LAZY Pure Views */
const Privacy = React.lazy(() => import('./app/views/Documents/Privacy'));
const AboutUs = React.lazy(() => import('./app/views/Landing/AboutUs'));
const Terms = React.lazy(() => import('./app/views/Documents/Terms'));
const Home = React.lazy(() => import('./app/views/Landing/Home'));
const Invite = React.lazy(() => import('./app/views/Invite'));

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string) => import(`./localization/${language}.json`)
    )
  )
  .init({
    lng: localStorage.getItem('wgt-i18') || 'en',
    fallbackLng: 'en'
  });

const models = new Models();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GrowthbookInit />
      <GrowthBookProvider growthbook={growthbook}>
        <Provider {...models.getStores()}>
          <BrowserRouter>
            <NotificationManager>
              <AccountManager />
              <KeyboardManager />
              <Header />
              <Routes>
                <Route path={routes.Confirmation} element={<Confirm />} />
                <Route path={routes.VerifyCert} element={<Verify />} />
                <Route
                  path={routes.Invitation}
                  element={
                    <Suspense fallback={<BlankLoading />}>
                      <Invite />
                    </Suspense>
                  }
                />

                <Route
                  path={routes.Home}
                  element={
                    <Suspense fallback={<HomeLazy />}>
                      <Home />
                    </Suspense>
                  }
                />
                <Route
                  path={routes.About}
                  element={
                    <Suspense fallback={<HomeLazy />}>
                      <AboutUs />
                    </Suspense>
                  }
                />
                <Route
                  path={routes.Terms}
                  element={
                    <Suspense fallback={<LegalLazy />}>
                      <Terms />
                    </Suspense>
                  }
                />
                <Route
                  path={routes.Privacy}
                  element={
                    <Suspense fallback={<LegalLazy />}>
                      <Privacy />
                    </Suspense>
                  }
                />

                <Route
                  path={routes.SignIn}
                  element={
                    <Suspense fallback={<BlankLoading />}>
                      <Auth />
                    </Suspense>
                  }
                />
                <Route
                  path={routes.SignUp}
                  element={
                    <Suspense fallback={<BlankLoading />}>
                      <Auth />
                    </Suspense>
                  }
                />

                <Route
                  path={routes.Dashboard}
                  element={
                    <Suspense fallback={<BlankLoading />}>
                      <DashboardManager />
                    </Suspense>
                  }
                >
                  <Route
                    path={routes.Dashboard}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <DashboardHome />
                      </Suspense>
                    }
                  />
                  <Route
                    path={routes.DashboardAccount}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <MyAccount />
                      </Suspense>
                    }
                  />
                  <Route
                    path={routes.DashboardCourses}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <DashboardBrowse />
                      </Suspense>
                    }
                  />
                  <Route
                    path={routes.DashboardCerts}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <DashboardCerts />
                      </Suspense>
                    }
                  />

                  <Route
                    path={routes.DashboardWebinar}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <DashboardWebinar />
                      </Suspense>
                    }
                  />

                  {/** Course by Id Routes */}
                  <Route
                    path={`${routes.DashboardCourseById}`}
                    element={
                      <Suspense fallback={<BlankLoading sidebar={true} />}>
                        <DashboardCourse />
                      </Suspense>
                    }
                  >
                    <Route
                      path={`${routes.DashboardCourseReviews}`}
                      element={<DashboardCourseReviews />}
                    />
                    <Route
                      path={`${routes.DashboardCourseModule}`}
                      element={
                        <Suspense fallback={<BlankLoading sidebar={true} />}>
                          <DashboardCourseModule />
                        </Suspense>
                      }
                    />
                  </Route>
                  {/** END Course by Id Routes */}

                  <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="*" element={<NotFound />} />

                <Route
                  path={routes.Quiz}
                  element={
                    <Suspense fallback={<BlankLoading />}>
                      <Quiz />
                    </Suspense>
                  }
                />
              </Routes>
              <Footer />
            </NotificationManager>
          </BrowserRouter>
        </Provider>
      </GrowthBookProvider>
    </ThemeProvider>
  </React.StrictMode>
);
