import { inject } from 'mobx-react';
import { Component } from 'react';

import KeyboardModel from '../models/domain/KeyboardModel';
import Controller from '../controllers/KeyboardManager';
import ViewModel from '../view-models/KeyboardManager';
import Models from '../models';

@inject(Models.type.KeyboardModel)
export default class KeyboardManager extends Component {
  private viewModel: ViewModel;

  constructor(props: any) {
    super(props);

    const model: KeyboardModel = props[Models.type.KeyboardModel];
    this.viewModel = new ViewModel(model);
  }

  render() {
    return <Controller viewModel={this.viewModel} />;
  }
}
