import AccountModel from '../models/domain/AccountModel';
import CoursesModel from '../models/domain/CoursesModel';

export default class HeaderViewModel {
  constructor(
    private readonly accountModel: AccountModel,
    private readonly coursesModel: CoursesModel
  ) {}

  public get isLogged(): boolean {
    return this.accountModel.isLogged;
  }

  public get isLoading(): boolean {
    return this.accountModel.isLoading;
  }

  get isSystemOffline(): boolean {
    return this.accountModel.isSystemOffline;
  }

  get selectedCourse() {
    return this.coursesModel.selectedCourse;
  }

  get singleCourseLoading() {
    return this.coursesModel.isLoadingSingleCourse;
  }
}
