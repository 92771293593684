/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PureComponent } from 'react';

import { ErrorLine, ErrorPage, ErrorName } from '../../../components/ErrorPage';
import { WithTranslationProps, withTranslation } from 'react-i18next';

class NotFound extends PureComponent<WithTranslationProps> {
  componentDidMount(): void {
    window.scrollTo(0, 0);
    window.document.title = 'Not Found - WeGrowTech';
  }

  render() {
    const { t } = this.props.i18n!;
    return (
      <ErrorPage>
        <img
          alt="404"
          src={`${process.env.REACT_APP_CDN}/lrn/images/7ce74dd0f8e4cb28057cd21f0d2ffde559768440.png`}
        />
        <ErrorLine>
          <ErrorName>{t('pageErrors.404')}</ErrorName>
        </ErrorLine>
      </ErrorPage>
    );
  }
}

export default withTranslation()(NotFound);
