import { Checkmark } from '@raresail/react-ionicons';
import { ComponentProps, PureComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTitle = styled.div`
  font-family: ${({ theme }) => theme.largeFont};
  color: ${({ theme }) => theme.textColor};
  font-weight: 400;
  font-size: 40px;
  margin-bottom: 10px;
  width: 100%;
`;

export const FormSubTitle = styled.div`
  color: ${({ theme }) => theme.secTextColor};
  opacity: 0.5;
  font-weight: 500;
  font-size: 18px;
  margin-top: -5px;
  padding-bottom: 40px;
  width: 100%;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.label`
  color: ${({ theme }) => theme.secTextColor};
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: 500;
  ${({ theme }) => theme.transitionLong}

  span {
    padding: 0 0 8px 0;
    display: block;
  }

  &:focus-within {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const FormInput = styled.input`
  background: ${({ theme }) => theme.elevatedBackgroundColor};
  border: 2px solid ${({ theme }) => theme.elevatedBackgroundColor};
  border-radius: 0.6em;
  font-size: 16px;

  padding: 10px 15px 12px;
  ${({ theme }) => theme.transitionLong}

  &:focus {
    border-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const FromCheckbox = styled.label`
  position: relative;
  height: 34px;
  width: 60px;
  margin: 5px 0;
  border-radius: 100vw;
  overflow: hidden;
  padding: 3px;
  cursor: pointer;
  input {
    pointer-events: none;
    position: absolute;
    opacity: 0;

    &:checked ~ [data-cb-bl] {
      margin-left: calc(60px - 34px);
    }

    &:checked ~ [data-cb-bg] {
      background: ${({ theme }) => theme.primaryColor};
    }
  }

  [data-cb-bl] {
    background: ${({ theme }) => theme.backgroundColor};
    height: 100%;
    aspect-ratio: 1;
    border-radius: 100%;
    ${({ theme }) => theme.transitionShort}
    ${({ theme }) => theme.shadow}
    z-index: 2;
    position: relative;
  }

  [data-cb-bg] {
    ${({ theme }) => theme.transitionShort}
    background: ${({ theme }) => theme.elevatedBackgroundColor};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
`;

export const FormOAuthButtons = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 0 0 60px 0;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  & > span {
    position: absolute;
    bottom: 22px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.secTextColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    opacity: 0.4;

    & > div {
      height: 1.5px;
      border-radius: 10px;
      width: 30px;
      background: ${({ theme }) => theme.secTextColor};
    }
  }

  &[data-locked='true'] {
    pointer-events: none;
  }

  &[data-locked='false'] {
    & > div[data-anim] {
      transition: 0.5s opacity;
      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const FormButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: 500;
  border-radius: 100vw;
  font-size: 14px;

  padding: 15px 15px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  height: fit-content;
  ${({ theme }) => theme.transitionLong}

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background: ${({ theme }) => theme.primaryColor};
    cursor: default;
    opacity: 0.2;
  }

  &[data-type='destructive'] {
    background: rgb(${({ theme }) => theme.errorRGB});
  }
`;

const FormCheckboxContainer = styled.label`
  position: relative;

  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: fit-content;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  ${({ theme }) => theme.transitionLong}
  color: ${({ theme }) => theme.secTextColor};
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    input ~ span {
      border-color: ${({ theme }) => theme.primaryColor};
    }

    &:has(a:hover) {
      color: ${({ theme }) => theme.secTextColor};

      input {
        & ~ span {
          border-color: ${({ theme }) => theme.elevatedBackgroundColor};
        }

        &:checked ~ span {
          border-color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
`;
const FormCheckboxCheck = styled.span`
  overflow: hidden;
  background: ${({ theme }) => theme.elevatedBackgroundColor};
  border: 2px solid ${({ theme }) => theme.elevatedBackgroundColor};
  ${({ theme }) => theme.transitionShort}
  position: relative;

  border-radius: 0.3em;
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;

  & > div {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;

    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.primaryTextColor};
    font-size: 20px;
  }
`;

const FormCheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 10px;
  height: 10px;
  pointer-events: none;
  left: calc((25px - 12px) / 2);
  top: -10px;

  &:checked ~ span {
    background: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};

    & > div {
      opacity: 1;
    }
  }
`;

export class FormCheckbox extends PureComponent<
  Omit<ComponentProps<typeof FormCheckboxInput>, 'type'> & {
    onChange?: (checked: boolean) => void;
  }
> {
  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange?.(e.target.checked);
  };

  render(): ReactNode {
    const { children, onChange: _, ...props } = this.props;
    return (
      <FormCheckboxContainer>
        {children}
        <FormCheckboxInput
          {...props}
          onChange={this.onChange}
          type="checkbox"
        />
        <FormCheckboxCheck>
          <div>
            <Checkmark />
          </div>
        </FormCheckboxCheck>
      </FormCheckboxContainer>
    );
  }
}

export const FormUnderLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const FormUnderLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
`;
