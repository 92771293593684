import { detect } from 'detect-browser';
import { sha256 } from 'js-sha256';

export const getGravatar = (email?: string) => {
  if (!email) {
    return null;
  }

  return `https://www.gravatar.com/avatar/${sha256(
    String(email).trim().toLowerCase()
  )}`;
};

export const getBrowser = (): string => {
  const browser = detect();

  switch (browser && browser.name) {
    case 'edge':
    case 'edge-ios':
    case 'edge-chromium':
    case 'chrome':
    case 'firefox':
    case 'opera':
    case 'safari':
      return browser?.name || 'unknown';
    default:
      return 'unknown';
  }
};
