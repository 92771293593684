import { PureComponent } from 'react';

export default class HomeLazy extends PureComponent {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '80px',
            padding: '0 60px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                margin: '10px 0',
                height: '21px',
                width: '405px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
            <div
              style={{
                margin: '20px 0',
                height: '96px',
                width: '100%',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
            <div
              style={{
                margin: '15px 0',
                height: '63px',
                width: '100%',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
            <div
              style={{
                margin: '20px 0',
                height: '46px',
                width: '149px',
                borderRadius: '10px',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: '30px 0'
            }}
          >
            <div
              style={{
                margin: '20px 0',
                height: '100%',
                width: '75%',
                aspectRatio: '1/1',
                borderRadius: '100%',
                animation: 'lazyLoad 2s linear infinite',
                background:
                  'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
                backgroundSize: '200% 100%'
              }}
            />
          </div>
        </div>
        <div
          style={{
            margin: '40px 0',
            width: '100%',
            height: '248px',
            animation: 'lazyLoad 2s linear infinite',
            background:
              'linear-gradient(90deg, rgba(242,242,242,0.5) 0%, rgba(213,213,213,0.5) 50%, rgba(242,242,242,0.5) 100%)',
            backgroundSize: '200% 100%'
          }}
        />
      </>
    );
  }
}
