import { makeAutoObservable, toJS } from 'mobx';

import {
  Course,
  EnrolledCourse,
  ExtendedCourse,
  Module,
  Quiz,
  Review
} from '../../@types/CoursesModel';
import { APIRoutes } from '../../../routes';

export default class CoursesModel {
  /**
   * Private variables to store data
   */
  private _enrolledCourses: EnrolledCourse[] = [];
  private enrollmentsLoading = false;
  private enrollmentLoaded = false;

  private _courses: Course[] = [];
  private loadingCourses = false;
  private firstLoaded = false;

  private loadingSingleCourse = false;
  private _selectedCourse?: ExtendedCourse;

  private loadingEnroll = false;

  private _reviews: Review[] = [];
  private loadingReviews = false;
  private _reviewsPerPage = -1;

  private loadingModule = false;
  private _selectedModule?: Module;

  private loadingCompleteModule = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Setters
   */

  public cleanupSelectedCourse() {
    this.setSelectedCourse();
    this.setReviews([], -1);
  }

  public cleanupSelectedModule() {
    this.setSelectedModule();
  }

  /**
   * Public Async Setters
   */

  public async loadEnrollments() {
    if (this.enrollmentsLoading || this.enrollmentLoaded) {
      return;
    }

    try {
      this.changeLoadingEnrollmentsState(true);

      const response = await fetch(APIRoutes.Enrollments, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.setEnrollments([], false);
        return;
      }

      const data = await response.json();
      this.setEnrollments(data, true);
    } catch (e) {
      this.setEnrollments([], false);
    }
  }

  public async loadCourses() {
    if (this.loadingCourses || this.firstLoaded) {
      return;
    }

    try {
      this.changeLoadingCoursesState(true);

      const response = await fetch(APIRoutes.Courses, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.setCourses([], false);
        return;
      }

      const data = await response.json();
      this.setCourses(data, true);
    } catch (e) {
      this.setCourses([], false);
    }
  }

  public async loadCourse(id: string): Promise<boolean> {
    if (this.loadingSingleCourse || !id) {
      return false;
    }

    try {
      this.changeLoadingSingleCourseState(true);

      const [response, quizzesResponse] = await Promise.all([
        fetch(`${APIRoutes.Courses}/${id}`, {
          method: 'GET',
          credentials: 'include'
        }),
        fetch(`${APIRoutes.Courses}/${id}/quizzes`, {
          method: 'GET',
          credentials: 'include'
        })
      ]);

      if (response.status !== 200) {
        this.setSelectedCourse();
        return false;
      }

      let quizzes = null;
      if (quizzesResponse.status === 200) {
        quizzes = await quizzesResponse.json();
      }

      const data = await response.json();
      this.setSelectedCourse({
        ...data,
        quizzes
      });
      return true;
    } catch (e) {
      this.setSelectedCourse();
      return false;
    }
  }

  public async enroll(id: string): Promise<string | undefined> {
    if (this.loadingEnroll || !id) {
      return;
    }

    try {
      this.changeLoadingEnrollState(true);

      const response = await fetch(`${APIRoutes.Enroll}/${id}`, {
        method: 'POST',
        credentials: 'include'
      });

      const data = await response.json();
      this.changeLoadingEnrollState(false);

      if (response.status !== 201) {
        return data?.message || 't:errors.unknown';
      }

      const currentCourse = this.selectedCourse;
      if (currentCourse) {
        this.setSelectedCourse(
          {
            ...currentCourse,
            enrolled: data
          },
          false
        );
      }

      const courses = this.courses;
      this.resetEnrollmentsLoaded();
      this.setCourses(
        courses.map((course) => ({
          ...course,
          enrolled: course.id === data.course_id || course.enrolled
        })),
        true,
        false
      );

      return;
    } catch {
      this.changeLoadingEnrollState(false);
      return 't:system.internalError';
    }
  }

  public async loadReviews(id: string, page = 0): Promise<boolean> {
    if (this.loadingReviews || !id) {
      return false;
    }

    try {
      this.changeLoadingReviewsState(true);

      const response = await fetch(
        `${APIRoutes.Courses}/${id}/reviews?page=${page}`,
        {
          method: 'GET',
          credentials: 'include'
        }
      );

      if (response.status !== 200) {
        this.setReviews([], -1);
        return false;
      }

      const ipp = parseInt(response.headers.get('Items-Per-Page') || '-1');
      const data = await response.json();
      this.setReviews(data, ipp);
      return true;
    } catch (e) {
      this.setReviews([], -1);
      return false;
    }
  }

  public async loadModule(id: string, module: string): Promise<boolean> {
    if (
      this.loadingModule ||
      !id ||
      !module ||
      this._selectedModule?.id === module
    ) {
      return false;
    }

    try {
      this.changeLoadingModuleState(true);

      const response = await fetch(`${APIRoutes.Courses}/${id}/${module}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.setSelectedModule();
        return false;
      }

      const data = await response.json();
      this.setSelectedModule(data);
      return true;
    } catch (e) {
      this.setSelectedModule();
      return false;
    }
  }

  public async completeModule(
    id: string,
    module: string,
    courseId: string
  ): Promise<boolean> {
    if (this.loadingCompleteModule || !id || !module) {
      return false;
    }

    try {
      this.changeLoadingCompleteModuleState(true);

      const response = await fetch(
        `${APIRoutes.Enroll}/${id}/complete/${module}`,
        {
          method: 'POST',
          credentials: 'include'
        }
      );

      if (response.status !== 201) {
        this.changeLoadingCompleteModuleState(false);
        return false;
      }

      let quizzes: any = undefined;

      try {
        const quizzesResponse = await fetch(
          `${APIRoutes.Courses}/${courseId}/quizzes`,
          {
            method: 'GET',
            credentials: 'include'
          }
        );

        if (quizzesResponse.status === 200) {
          quizzes = await quizzesResponse.json();
        }
      } catch {
        //
      }

      const data = await response.json();
      this.resetEnrollmentsLoaded();
      this.updateSelectedMeta(data, quizzes);
      this.changeLoadingCompleteModuleState(false);
      return true;
    } catch (e) {
      this.changeLoadingCompleteModuleState(false);
      return false;
    }
  }

  /**
   * Private Sync Setters
   */
  private changeLoadingCoursesState(value: boolean) {
    this.loadingCourses = value;
  }

  private changeLoadingReviewsState(value: boolean) {
    this.loadingReviews = value;
  }

  private changeLoadingEnrollState(value: boolean) {
    this.loadingEnroll = value;
  }

  private changeLoadingEnrollmentsState(value: boolean) {
    this.enrollmentsLoading = value;
  }

  private resetEnrollmentsLoaded() {
    this.enrollmentLoaded = false;
  }

  private changeLoadingSingleCourseState(value: boolean) {
    this.loadingSingleCourse = value;
  }

  private setCourses(
    courses: Course[],
    firstLoaded: boolean,
    updateLoading = true
  ) {
    this._courses = courses;
    if (updateLoading) {
      this.firstLoaded = firstLoaded;
      this.loadingCourses = false;
    }
  }

  private setEnrollments(
    courses: EnrolledCourse[],
    enrollmentLoaded: boolean,
    updateLoading = true
  ) {
    this._enrolledCourses = courses;
    if (updateLoading) {
      this.enrollmentLoaded = enrollmentLoaded;
      this.enrollmentsLoading = false;
    }
  }

  private updateSelectedMeta(
    meta: { completed: Record<string, boolean> },
    quizzes?: Record<string, Quiz>
  ) {
    const course = this.selectedCourse;
    if (!course) {
      return;
    }

    this._selectedCourse = {
      ...course,
      enrolled: course?.enrolled
        ? {
            ...course.enrolled,
            metadata: {
              ...course.enrolled.metadata,
              ...meta
            }
          }
        : course.enrolled,
      quizzes: quizzes || course.quizzes
    };
  }

  private setSelectedCourse(course?: ExtendedCourse, updateLoading = true) {
    this._selectedCourse = course;

    if (updateLoading) this.loadingSingleCourse = false;
  }

  private setReviews(reviews: Review[], ipp: number, updateLoading = true) {
    this._reviews = reviews;
    this._reviewsPerPage = ipp;

    if (updateLoading) {
      this.loadingReviews = false;
    }
  }

  private setSelectedModule(course?: Module, updateLoading = true) {
    this._selectedModule = course;

    if (updateLoading) this.loadingModule = false;
  }

  private changeLoadingModuleState(value: boolean) {
    this.loadingModule = value;
  }

  private changeLoadingCompleteModuleState(value: boolean) {
    this.loadingCompleteModule = value;
  }

  /**
   * Getters
   */
  public get isLoadingCourses(): boolean {
    return this.loadingCourses;
  }

  public get isEnrolling(): boolean {
    return this.loadingEnroll;
  }

  public get isLoadingEnrollments(): boolean {
    return this.enrollmentsLoading;
  }

  public get isLoadingSingleCourse(): boolean {
    return this.loadingSingleCourse;
  }

  public get isLoadingReviews(): boolean {
    return this.loadingReviews;
  }

  public get courses() {
    return toJS(this._courses);
  }

  public get enrollments() {
    return toJS(this._enrolledCourses);
  }

  public get selectedCourse() {
    return toJS(this._selectedCourse);
  }

  public get reviews() {
    return toJS(this._reviews);
  }

  public get reviewsPerPage() {
    return this._reviewsPerPage;
  }

  public get selectedModule() {
    return toJS(this._selectedModule);
  }

  public get isLoadingModule() {
    return this.loadingModule;
  }

  public get isCompletingCourse() {
    return this.loadingCompleteModule;
  }
}
