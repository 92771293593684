import AsyncLock from 'async-lock';
import EventEmitter from 'events';

import { SocketAction, SocketMessage } from '../@types/AccountManager';
import { FeatureFlag } from '../@types/FeatureFlag';
import growthbook from '../../growthbook';

class NotificationEvents {
  private readonly lock = new AsyncLock();
  private readonly emitter = new EventEmitter();

  private timeoutCheck: NodeJS.Timeout | undefined;
  private listeners = new Set();
  private alreadyChecked = 0;

  public subscribe = (listener: (payload: SocketMessage) => void) => {
    if (this.listeners.has(listener)) {
      return;
    }

    this.listeners.add(listener);
    this.emitter.addListener(NotificationEvents.name, listener);
  };

  public unsubscribe = (listener: (payload: SocketMessage) => void) => {
    this.listeners.delete(listener);
    this.emitter.removeListener(NotificationEvents.name, listener);
  };

  // Private Event Functions

  private publish = (...args: any) =>
    this.emitter.emit(NotificationEvents.name, ...args);

  // Socket
  private socket: WebSocket | null = null;

  private close = () => {
    if (this.socket) {
      console.log('Notification System', 'closed');
      this.socket.close();
      this.socket = null;
    }
  };

  public stateChanged = (isLoggedIn: boolean) => {
    this.lock.acquire('setup', async () => {
      if (this.timeoutCheck) {
        clearTimeout(this.timeoutCheck);
        this.timeoutCheck = undefined;
      }

      if (!isLoggedIn) {
        this.alreadyChecked = 0;
        this.close();
        return;
      }

      if (
        !growthbook.isOn(FeatureFlag.Notifications) &&
        this.alreadyChecked < 2
      ) {
        this.alreadyChecked++;
        this.timeoutCheck = setTimeout(
          () => this.stateChanged(isLoggedIn),
          1000
        );
        return;
      }

      if (!growthbook.isOn(FeatureFlag.Notifications)) return;
      this.alreadyChecked = 0;

      if (this.socket && this.socket.readyState < 2) return;

      this.socket = new WebSocket(
        `${process.env.REACT_APP_API_ENDPOINT}/notifications`.replace(
          'https',
          'wss'
        )
      );

      this.socket.onopen = (e) => {
        console.log('Notification System', e.type);
        this.socket?.send(
          JSON.stringify({
            action: 'accept'
          })
        );
      };

      this.socket.onclose = (e) => {
        console.log('Notification System', e.type);
        this.close();

        if ([4000, 1006].includes(e.code)) {
          this.publish({
            action: SocketAction.Error,
            payload: {
              message: 't:errors.notSocket'
            }
          });
        } else {
          setTimeout(() => this.stateChanged(isLoggedIn), 1000);
        }
      };

      this.socket.onerror = (e) => {
        console.log('Notification Error', e);
      };

      this.socket.onmessage = (e) => {
        this.publish(JSON.parse(e.data));
      };
    });
  };
}

const instance = new NotificationEvents();
export default instance;
