import { inject } from 'mobx-react';
import { Component } from 'react';

import AccountModel from '../models/domain/AccountModel';
import Controller from '../controllers/AccountManager';
import ViewModel from '../view-models/AccountManager';
import Models from '../models';

@inject(Models.type.AccountModel)
export default class AccountManager extends Component {
  private readonly viewModel: ViewModel;

  constructor(props: any) {
    super(props);
    const accountModel: AccountModel = props[Models.type.AccountModel];
    this.viewModel = new ViewModel(accountModel);
  }

  render() {
    return <Controller viewModel={this.viewModel} />;
  }
}
