import { Component, Suspense, lazy } from 'react';
import { observer } from 'mobx-react';

import withRouter, { WithRouterProps } from '../../components/withRouter';
import { ControllerProps } from '../@types/Header';
import routes from '../../routes';
import growthbook from '../../growthbook';
import { getBrowser } from '../../tools';

const DashboardHeader = lazy(() => import('../views/Header/Dashboard'));
const LandingHeader = lazy(() => import('../views/Header'));

@observer
class HeaderController extends Component<WithRouterProps<ControllerProps>> {
  componentDidUpdate(): void {
    growthbook.updateAttributes({
      query: this.props.location.search.replace('?', ''),
      path: this.props.location.pathname,
      host: window.location.hostname,
      deviceType: 'desktop',
      browser: getBrowser()
    });
  }

  render() {
    const { viewModel } = this.props;

    if (this.props.location.pathname === routes.Quiz) {
      return <></>;
    }

    if (
      viewModel.isLogged &&
      this.props.location.pathname.includes('dashboard')
    ) {
      return (
        <Suspense>
          <DashboardHeader
            loggedIn={viewModel.isLogged}
            isLoading={viewModel.isLoading}
            pathname={this.props.location.pathname}
            selectedCourse={viewModel.selectedCourse}
            singleCourseLoading={viewModel.singleCourseLoading}
          />
        </Suspense>
      );
    }

    return (
      <Suspense>
        <LandingHeader
          loggedIn={viewModel.isLogged}
          isLoading={viewModel.isLoading}
          pathname={this.props.location.pathname}
          isSystemOffline={viewModel.isSystemOffline}
        />
      </Suspense>
    );
  }
}

export default withRouter<ControllerProps>(HeaderController);
