import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
  Location,
  Params
} from 'react-router-dom';

export type WithRouterProps<T = any> = T & {
  params: Readonly<Params<string>>;
  navigate: NavigateFunction;
  location: Location;
};

const withRouter =
  <T,>(Component: React.ElementType) =>
  (props: T) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  };

export default withRouter;
