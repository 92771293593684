import { makeAutoObservable, toJS } from 'mobx';
import AsyncLock from 'async-lock';

import { QuestionKey, Quiz } from '../../@types/Quiz';
import { APIRoutes } from '../../../routes';

const lock = new AsyncLock({
  maxPending: 0
});

export default class QuizzesModel {
  /**
   * Private variables to store data
   */
  private _sendingAnswer = false;
  private _loading = false;
  private _atIndex = -1;
  private _quiz?: Quiz;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Setters
   */

  /**
   * Public Async Setters
   */
  public load(id: string): void {
    lock
      .acquire('load', async () => {
        if (this._loading || !id) {
          return;
        }

        try {
          this.setQuiz(undefined, true);

          const response = await fetch(`${APIRoutes.Quiz}/${id}`, {
            method: 'POST',
            credentials: 'include'
          });

          if (response.status !== 200) {
            this.setQuiz();
            return;
          }

          this.setQuiz(await response.json());
        } catch {
          this.setQuiz();
        }
      })
      .catch(() => {
        //
      });
  }

  public async answer(
    id: string,
    question: number,
    answers: QuestionKey[]
  ): Promise<void> {
    if (this._sendingAnswer || !id) {
      return;
    }

    this.setSendingAnswer(true);
    const adaptedAnswers = !answers.length ? ['.'] : answers;

    try {
      const response = await fetch(`${APIRoutes.Quiz}/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify({
          answers: adaptedAnswers,
          question
        })
      });

      if (response.status !== 204) {
        throw new Error('');
      }

      this.setQuestionIndex(this._atIndex + 1);
    } catch {
      //
    } finally {
      this.setSendingAnswer(false);
    }
  }

  /**
   * Private Sync Setters
   */

  private setQuiz(value?: Quiz, loading = false) {
    this._loading = loading;
    this._quiz = value;
    this._atIndex = 0;
  }

  private setSendingAnswer(value: boolean) {
    this._sendingAnswer = value;
  }

  private setQuestionIndex(index: number) {
    this._atIndex = index;
  }

  /**
   * Getters
   */
  public get isLoading(): boolean {
    return this._loading;
  }

  public get questionIndex(): number {
    return this._atIndex;
  }

  public get quiz(): Quiz | undefined {
    return toJS(this._quiz);
  }

  public get sending(): boolean {
    return this._sendingAnswer;
  }
}
