import { makeAutoObservable, toJS } from 'mobx';

import { Certificate } from '../../@types/CertModel';
import { APIRoutes } from '../../../routes';

export default class CertModel {
  /**
   * Private variables to store data
   */
  private _certificates: Certificate[] = [];
  private _loadingCerts = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Async Setters
   */

  public async loadCertificates() {
    if (this._loadingCerts) {
      return;
    }

    try {
      this.changeLoadingState(true);

      const response = await fetch(APIRoutes.Certificates, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status !== 200) {
        this.setCerts([]);
        return;
      }

      const data = await response.json();
      this.setCerts(data);
    } catch (e) {
      this.setCerts([]);
    }
  }

  /**
   * Private Sync Setters
   */

  private changeLoadingState(value: boolean) {
    this._loadingCerts = value;
  }

  private setCerts(value: Certificate[]) {
    this._certificates = value;
    this._loadingCerts = false;
  }

  /**
   * Getters
   */

  public get isLoadingCerts(): boolean {
    return this._loadingCerts;
  }

  public get certificates() {
    return toJS(this._certificates);
  }
}
