import KeyboardModel from './domain/KeyboardModel';
import AccountModel from './domain/AccountModel';
import CoursesModel from './domain/CoursesModel';
import QuizzesModel from './domain/QuizzesModel';
import WebinarModel from './domain/WebinarModel';
import CertModel from './domain/CertModel';

/**
 * When you add a new model, you have to add it here.
 */
export default class Store {
  /**
   * Define a type of the Model so it can be found.
   */
  static type = {
    KeyboardModel: 'keyboard',
    CertModel: 'certificates',
    AccountModel: 'account',
    CoursesModel: 'courses',
    QuizzesModel: 'quizzes',
    WebinarModel: 'webinar'
  };

  /**
   * Define a variable for the model so it can be used.
   */
  private keyboardModel: KeyboardModel;
  private accountModel: AccountModel;
  private coursesModel: CoursesModel;
  private quizzesModel: QuizzesModel;
  private webinarModel: WebinarModel;
  private certModel: CertModel;

  /**
   * Create an instance for each model.
   */
  constructor() {
    this.keyboardModel = new KeyboardModel();
    this.accountModel = new AccountModel();
    this.coursesModel = new CoursesModel();
    this.quizzesModel = new QuizzesModel();
    this.webinarModel = new WebinarModel();
    this.certModel = new CertModel();
  }

  /**
   * Add the model in this object so it can be sent to the controllers.
   */
  public getStores = () => ({
    [Store.type.KeyboardModel]: this.keyboardModel,
    [Store.type.AccountModel]: this.accountModel,
    [Store.type.CoursesModel]: this.coursesModel,
    [Store.type.QuizzesModel]: this.quizzesModel,
    [Store.type.WebinarModel]: this.webinarModel,
    [Store.type.CertModel]: this.certModel
  });
}
