/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PureComponent, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { nanoid } from 'nanoid';

import {
  INotification,
  NotificationContext,
  NotificationManagerState,
  NotificationType
} from './NotificationContext';
import { WithTranslationProps, withTranslation } from 'react-i18next';
import {
  CheckmarkCircle,
  Close,
  CloseCircle,
  InformationCircle
} from '@raresail/react-ionicons';

class NotificationManager extends PureComponent<
  PropsWithChildren<WithTranslationProps>,
  NotificationManagerState
> {
  state = {
    notifications: []
  };

  add = (
    type: NotificationType,
    message: string,
    expireMs = 30 * 1000,
    deduplication = false
  ) => {
    if (
      deduplication &&
      this.state.notifications.find((n: any) => n.message === message)
    ) {
      return;
    }

    const id = nanoid();
    this.setState((state) => ({
      notifications: [...state.notifications, { type, message, id }]
    }));

    setTimeout(() => {
      this.remove(id);
    }, expireMs);
  };

  remove = (id: string) => {
    this.setState((state) => ({
      notifications: state.notifications.filter(
        (notification) => notification.id !== id
      )
    }));
  };

  render() {
    const { t } = this.props.i18n!;

    return (
      <>
        <NotificationContext.Provider value={{ add: this.add }}>
          {this.props.children}
        </NotificationContext.Provider>
        <Container>
          {this.state.notifications.map((notification: INotification) => {
            let IonIcon = InformationCircle;
            let c = '';
            if (notification.type === NotificationType.Error) {
              IonIcon = CloseCircle;
              c = 'error';
            } else if (notification.type === NotificationType.Success) {
              IonIcon = CheckmarkCircle;
              c = 'success';
            }

            const isTranslated = notification.message.startsWith('t:');

            return (
              <Notification key={notification.id} className={c}>
                <div className="nb" />
                <div className="nic">
                  <IonIcon />
                </div>
                <div style={{ lineHeight: '18px' }}>
                  {isTranslated
                    ? t(notification.message.replace('t:', ''))
                    : notification.message}
                </div>
                <div
                  className="nbt"
                  onClick={() => this.remove(notification.id)}
                >
                  <Close />
                </div>
              </Notification>
            );
          })}
        </Container>
      </>
    );
  }
}

const Container = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10000;
  width: 100%;
  max-width: 350px;

  @keyframes notificationLoad {
    0% {
      transform: translateX(350px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media (pointer: none), (pointer: coarse) {
    bottom: 128px;
  }
`;

const Notification = styled.div`
  background: ${({ theme }) => theme.elevatedBackgroundColor};
  color: rgb(${({ theme }) => theme.infoRGB});
  font-size: 14px;
  border-radius: 0.6em;
  overflow: hidden;
  position: relative;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;
  animation: 0.3s ease-out 0s 1 notificationLoad;
  box-shadow: rgba(0, 0, 0, 0.08) 2px 4px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 2px 4px 12px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .nb {
    position: absolute;
    background: rgba(${({ theme }) => theme.infoRGB}, 0.2);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .nic {
    font-size: 24px;
  }

  .nbt {
    cursor: pointer;
    margin-left: auto;
    font-size: 24px;
    color: rgba(${({ theme }) => theme.elevatedTextColorRGB}, 0.2);
    transition: 0.5s;

    &:hover {
      color: rgba(${({ theme }) => theme.elevatedTextColorRGB}, 0.6);
    }
  }

  &.error {
    color: rgb(${({ theme }) => theme.errorRGB});

    .nb {
      background: rgba(${({ theme }) => theme.errorRGB}, 0.2);
    }
  }

  &.success {
    color: rgb(${({ theme }) => theme.successRGB});

    .nb {
      background: rgba(${({ theme }) => theme.successRGB}, 0.2);
    }
  }
`;

export default withTranslation()(NotificationManager);
