import { makeAutoObservable } from 'mobx';

export default class KeyboardModel {
  /**
   * Private variables to store data
   */

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Public Async Setters
   */

  /**
   * Private Sync Setters
   */

  /**
   * Getters
   */
}
