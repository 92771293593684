import { Provider } from './app/@types/Auth';

const routes = {
  DashboardCourseReviews: '/dashboard/courses/:id/reviews',
  DashboardCourseModule: '/dashboard/courses/:id/:module',
  DashboardCourseById: '/dashboard/courses/:id',
  DashboardCerts: '/dashboard/certificates',
  DashboardCourses: '/dashboard/courses',
  DashboardWebinar: '/dashboard/webinar',
  DashboardAccount: '/dashboard/account',
  Dashboard: '/dashboard',
  About: '/about',
  Home: '/',

  Confirmation: '/confirmation',
  Invitation: '/invitation',

  // Auth
  SignIn: '/sign-in',
  SignUp: '/sign-up',

  // Legal
  Terms: '/terms-and-conditions',
  Privacy: '/privacy-policy',

  // Certifications
  VerifyCert: '/verify',

  Quiz: '/quiz'
};

export default routes;

export const APIRoutes = {
  AuthAccountEmail: `${process.env.REACT_APP_API_ENDPOINT}/auth/account/email`,
  AuthAccountData: `${process.env.REACT_APP_API_ENDPOINT}/auth/account/data`,
  DownloadData: `${process.env.REACT_APP_API_ENDPOINT}/auth/data/download`,
  AuthRequestData: `${process.env.REACT_APP_API_ENDPOINT}/auth/data/@me`,
  AuthIdentity: `${process.env.REACT_APP_API_ENDPOINT}/auth/identity`,
  AuthSessions: `${process.env.REACT_APP_API_ENDPOINT}/auth/sessions`,
  Certificates: `${process.env.REACT_APP_API_ENDPOINT}/certificates`,
  AuthAccount: `${process.env.REACT_APP_API_ENDPOINT}/auth/account`,
  AuthSignUp: `${process.env.REACT_APP_API_ENDPOINT}/auth/sign-up`,
  AuthSignIn: `${process.env.REACT_APP_API_ENDPOINT}/auth/sign-in`,
  Enrollments: `${process.env.REACT_APP_API_ENDPOINT}/courses/@me`,
  AuthVerify: `${process.env.REACT_APP_API_ENDPOINT}/auth/verify`,
  Enroll: `${process.env.REACT_APP_API_ENDPOINT}/courses/enroll`,
  AuthMe: `${process.env.REACT_APP_API_ENDPOINT}/auth/@me`,
  Courses: `${process.env.REACT_APP_API_ENDPOINT}/courses`,
  Webinar: `${process.env.REACT_APP_API_ENDPOINT}/webinar`,
  Quiz: `${process.env.REACT_APP_API_ENDPOINT}/quiz`,

  // OAuth
  OIDC: (provider: Provider) =>
    `${process.env.REACT_APP_API_ENDPOINT}/oidc/${provider}`
};
